
import { Options, Vue } from "vue-class-component";
import { kBrandCulturePageContent } from "@/config/Common/brand_culture_data";

@Options({
  components: {},
  data() {
    return {
      info: kBrandCulturePageContent.threeDesire,
    };
  },
})
export default class ThreeDesire extends Vue {}
