export const kBrandCulturePageContent = {
  pageName: "品牌文化",
  threeDesire: {
    title: "归农三大愿望",
    list: [
      {
        title: "助农兴农",
        img: require("@/assets/guinong/desire_icon_01.png"),
        content: "用市场推动农场，让农户有一份合理体面的收入，让务农变成一份令人尊敬的职业。",
      },
      {

        title: "推行自然农法",
        img: require("@/assets/guinong/desire_icon_02.png"),
        content: "推行自然农法，守护家人健康，守护大地净土，让我们的食物不仅有营养，还更加有力量，并且让普通消费者也消费得起生态天然的产品。",
      },
      {
        title: "提倡友善商业文化",
        img: require("@/assets/guinong/desire_icon_03.png"),
        content: `提倡友善、理性的商业文化。反对崇洋媚外，反对肤浅盲从，化解买卖对立，让消费者参与到企业经营中，成为主人，让我们一起打造“共益”商业体。`,
      },
    ],
  },
  tenValue: {
    title: '“十大”消费价值观与生活美学',
    list: [
      {
        number: "01",
        content: "一切源于天然\n真实高于一切",
      },
      {
        number: "02",
        content: "不负农人苦心\n分享自然真味",
      },
      {
        number: "03",
        content: "集结新农人\n关怀老农人",
      },
      {
        number: "04",
        content: "带着善意\n才能识别真正的美好",
      },
      {
        number: "05",
        content: "心系自然\n美由心生",
      },
      {
        number: "06",
        content: "中国美\n自然美\n内在美",
      },
      {
        number: "07",
        content: "发现植物的味道\n发现植物的力量",
      },
      {
        number: "08",
        content: "友善耕种\n自然农法",
      },
      {
        number: "09",
        content: "反对崇洋媚外\n反对过度包装",
      },
      {
        number: "10",
        content: "拙朴\n简单\n自然",
      },
    ],
  }
};
